/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.experience-component .tabs-container {
  display: block; }
  .experience-component .tabs-container.edit-mode {
    border: 0.625rem solid #ffdf5e;
    position: relative; }
    .experience-component .tabs-container.edit-mode:before {
      content: "Tabbed Content Edit Mode";
      padding: 0.3125rem 0.625rem 0.625rem;
      position: absolute;
      top: -0.4375rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 200;
      background-color: #ffdf5e; }

.experience-commerce_assets-tab {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

@media (min-width: 768px) {
  .experience-commerce_layouts-tabPanel {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.4s ease-in-out 0.2s; }
    .edit-mode .experience-commerce_layouts-tabPanel, .experience-commerce_layouts-tabPanel.js-active {
      max-height: 10000px;
      opacity: 1; } }

@media (min-width: 768px) {
  .experience-commerce_layouts-tabPanel.js-active {
    max-height: 10000px;
    opacity: 1; } }

.experience-commerce_layouts-tabPanel.js-active + .experience-commerce_layouts-tabPanel .tab-panel {
  border-width: 0 0 0.0625rem; }
  @media (min-width: 768px) {
    .experience-commerce_layouts-tabPanel.js-active + .experience-commerce_layouts-tabPanel .tab-panel {
      border-width: 0; } }

.tab-anchor {
  width: 100%;
  text-align: left;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--color-black);
  position: relative;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  white-space: unset;
  background-color: transparent;
  border: 0;
  padding: 1.375rem 1.75rem 1.375rem 0;
  font-family: "Neue Haas Grotesk Text Round", sans-serif;
  font-weight: 400; }
  @media (min-width: 768px) {
    .tab-anchor {
      display: block;
      width: 100%;
      height: 100%;
      font-family: "Neue Haas Grotesk Text Round", sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.125rem;
      padding: 1rem;
      border: solid var(--color-grey3);
      border-width: 0 0 0.125rem;
      transition: background 0.3s ease-in-out, border 0.3s ease-in-out; }
      .tab-anchor:hover {
        border-width: 0 0 0.25rem; }
      .js-active .tab-anchor {
        font-family: "Neue Haas Grotesk Text Round Bold", sans-serif;
        font-weight: 700;
        border-width: 0 0 0.3125rem;
        border-color: var(--color-black);
        pointer-events: none; } }
  .tab-anchor::before, .tab-anchor::after {
    content: '';
    transition: 0.3s ease;
    width: 1.1875rem;
    height: 0.1875rem;
    background: var(--color-black);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    transform-origin: 50% 0%; }
    @media (min-width: 768px) {
      .tab-anchor::before, .tab-anchor::after {
        display: none; } }
  .tab-anchor::before {
    transform: rotate(-90deg) translate(0, -50%); }
  .tab-anchor::after {
    opacity: 1;
    transform: rotate(-180deg) translate(0, -50%); }
  .js-active .tab-anchor::before {
    transform: rotate(0deg) translate(0, -50%); }
  .js-active .tab-anchor::after {
    opacity: 0;
    transform: rotate(0deg) translate(0, -50%); }

.tab-panel {
  border: solid var(--color-grey300);
  border-width: 0.0625rem 0;
  padding: 0; }
  @media (min-width: 768px) {
    .tab-panel {
      border-width: 0; } }
  .tab-panel__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in, padding 0.3s ease-in-out; }
    @media (min-width: 768px) {
      .tab-panel__content {
        max-height: 100vh;
        padding: 2.5rem 0; } }
  .js-active .tab-panel {
    padding: 0 0 0.9375rem; }
    .js-active .tab-panel__content {
      max-height: 100000px;
      padding: 0.625rem 0; }
      @media (min-width: 768px) {
        .js-active .tab-panel__content {
          padding: 2.5rem 0; } }

.tab-view-enabled .experience-commerce_layouts-tabPanel {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.4s ease-in-out 0.2s; }
  .edit-mode .tab-view-enabled .experience-commerce_layouts-tabPanel, .tab-view-enabled .experience-commerce_layouts-tabPanel.js-active {
    max-height: 10000px;
    opacity: 1; }
  .tab-view-enabled .experience-commerce_layouts-tabPanel.js-active {
    max-height: 10000px;
    opacity: 1; }
    .tab-view-enabled .experience-commerce_layouts-tabPanel.js-active + .experience-commerce_layouts-tabPanel .tab-panel {
      border-width: 0; }

.tab-view-enabled .tab-anchor {
  display: block;
  width: 100%;
  height: 100%;
  font-family: "Neue Haas Grotesk Text Round", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.125rem;
  padding: 1rem;
  border: solid var(--color-grey3);
  border-width: 0 0 0.125rem;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
  white-space: nowrap; }
  @media (hover: hover) {
    .tab-view-enabled .tab-anchor:hover {
      border-width: 0 0 0.25rem; } }
  .tab-view-enabled .tab-anchor::before, .tab-view-enabled .tab-anchor::after {
    display: none; }

.tab-view-enabled .tab-panel {
  border-width: 0; }
  .tab-view-enabled .tab-panel__content {
    max-height: 100vh;
    padding: 2.5rem 0; }
  .tab-view-enabled .tab-panel .tab-anchor--mobile {
    display: none; }

.tab-view-enabled .js-active .tab-anchor {
  font-family: "Neue Haas Grotesk Text Round Bold", sans-serif;
  font-weight: 700;
  border-width: 0 0 0.3125rem;
  border-color: var(--color-black);
  pointer-events: none; }

.tab-view-enabled .js-active .tab-panel {
  padding: 0 0 0.9375rem; }
  .tab-view-enabled .js-active .tab-panel__content {
    padding: 2.5rem 0; }

.tab-view-enabled .tabs {
  overflow: hidden; }

.tab-view-enabled .draggableTabs {
  display: flex;
  cursor: grab;
  position: relative;
  transition: transform 0.2s ease;
  /* Smooth transition */ }

.tab-view-enabled .draggableTabs:active {
  cursor: grabbing; }
